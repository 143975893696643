import _img from "../pages/image/img";

export const devices = [
  {
    src: _img.yinshuaji,
    name: "yinshuaji",
    key: "yinshuaji",
    param: _img.yinshuajicanshu,
    desc: "yinshuaji_desc",
  },
  {
    src: _img.jianceshebei_spi_3d,
    name: "jianceshebei_spi_3d",
    key: "jianceshebei_spi_3d",
    param: _img.jianceshebeicanshu_spi_3d,
    desc: "jianceshebei_spi_3d_desc",
  },
  {
    src: _img.jianceshebei,
    name: "jianceshebei",
    key: "jianceshebei",
    desc: "jianceshebei_desc",
  },
  {
    src: _img.tiepianji,
    name: "tiepianji",
    key: "tiepianji",
    param: _img.tiepianjicanshu,
    desc: "tiepianji_desc",
  },
  {
    src: _img.jianceshebei_aoi_3d,
    name: "jianceshebei_aoi_3d",
    key: "jianceshebei_aoi_3d",
    param: _img.jianceshebeicanshu_aoi_3d,
    desc: "jianceshebei_aoi_3d_desc",
  },
  {
    src: _img.jianceshebei_1,
    name: "jianceshebei_1",
    key: "jianceshebei_1",
    param: _img.jianceshebeicanshu_1,
    desc: "jianceshebei_1_desc",
  },
  {
    src: _img.jianceshebei_gex_ray,
    name: "jianceshebei_gex_ray",
    key: "jianceshebei_gex_ray",
    param: _img.jianceshebeicanshu_gex_ray,
    desc: "jianceshebei_gex_ray_desc",
  },
  {
    src: _img.huiliuhan,
    name: "huiliuhan",
    key: "huiliuhan",
    param: _img.huiliuhancanshu,
    desc: "huiliuhan_desc",
  },
  {
    src: _img.huiliuhan_danqi,
    name: "huiliuhan_danqi",
    key: "huiliuhan_danqi",
    param: _img.huiliuhancanshu_danqi,
    desc: "huiliuhan_danqi_desc",
  },
  {
    src: _img.bofenghan,
    name: "bofenghan",
    key: "bofenghan",
    param: _img.bofenghancanshu,
    desc: "bofenghan_desc",
  },
  {
    src: _img.bofenghan_1,
    name: "bofenghan_1",
    key: "bofenghan_1",
    param: _img.bofenghancanshu_1,
    desc: "bofenghan_1_desc",
  },
  {
    src: _img.bofenghan_xuanzexing,
    name: "bofenghan_xuanzexing",
    key: "bofenghan_xuanzexing",
    param: _img.bofenghancanshu_xuanzexing,
    desc: "bofenghan_xuanzexing_desc",
  },
  {
    src: _img.sanfangtufushebei,
    name: "sanfangtufushebei",
    key: "sanfangtufushebei",
    param: _img.sanfangtufushebeicanshu,
    desc: "sanfangtufushebei_desc",
  },
  {
    src: _img.weixiushebei,
    name: "weixiushebei",
    key: "weixiushebei",
    param: _img.weixiushebeicanshu,
    desc: "weixiushebei_desc",
  },
  {
    src: _img.fuzhushebei,
    name: "fuzhushebei",
    key: "fuzhushebei",
    param: _img.fuzhushebeicanshu,
    desc: "fuzhushebei_desc",
  },
  {
    src: _img.fuzhushebei_1,
    name: "fuzhushebei_1",
    key: "fuzhushebei_1",
    param: _img.fuzhushebeicanshu_1,
    desc: "fuzhushebei_1_desc",
  },
];
